.identify-patient-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  gap: 10px;
  background-color: #fff;
}
.identify-patient-container .form-wrapper {
  background-color: #f7f7f7;
  padding: 0 0 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 450px;
}

.identify-patient-container .link {
  font-size: 14px;
}
