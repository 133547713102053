/*.viewheader-component{*/
/*    width: 82.9vw;*/
/*    height: 70px;*/
/*    box-shadow:4px 4px 4px rgb(131, 114, 114);*/
/*}*/
/*.nav{*/
/*   padding: 6px;*/

/*}*/
/*.view_header_text{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    margin-left: 10px;*/
/*    margin-top: -12px;*/
/*}*/

/*.navbar-brand{*/
/*    color: #004374;*/
/*    font-weight:500 ;*/
/*}*/

/*@media screen and (max-width:1024px) {*/
/*    .header-component{*/
/*        width: 82vw;*/
/*    */
/*    }*/
/*    */
/*    .navbar-brand{*/
/*        font-size: 18px;*/
/*        font-weight: 700;*/
/*        margin-left: 20px;*/
/*        !* text-align: center; *!*/
/*        margin-top: 20px;*/
/*        display: flex;*/
/*        align-items: center;*/
/*    }*/
/*    .container-fluid{*/
/*        display: flex;*/
/*        align-items: center;*/
/*    }*/
/*    .nav-item{*/
/*        margin-top: -50px;*/
/*    }*/
/*}*/
/*@media screen and (max-width:700px) {*/
/*.header-component{*/
/*    width: 100vw;*/

/*}*/

/*.navbar-brand{*/
/*    font-size: 18px;*/
/*    font-weight: 700;*/
/*    margin-left: 20px;*/
/*}*/
/*}*/