.selfonboarding-welcome {
  max-width: 650px;
  margin: auto;
}

.selfonboarding-welcome h1 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.selfonboarding-welcome h2 {
  font-size: 16px;
  font-weight: bold;
}

.selfonboarding-welcome p {
  font-size: 16px;
}

.selfonboarding-welcome .secondary {
  font-size: 14px;
  color: #666b78;
}

.selfonboarding-welcome button.ant-btn-primary {
  min-width: 321px;
}

.selfonboarding-welcome .device-card {
  width: 148px;
  height: 126px;
  border-radius: 14px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.selfonboarding-welcome .device-card img {
  width: 53px;
  height: 53px;
}

.map-container {
  width: 100%;
  height: 40vh;
  border-radius: 20px;
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
