.main-layout .ant-layout-header {
  width: 100%;
  display: flex;
  background: #fff;
  align-items: center;
  padding-inline: 48px;
  justify-content: space-between;
}

/* .main-layout.dark-theme .ant-layout-header {
  background: #141414;
} */

.ant-layout-header .navbar-left .anticon {
  margin-top: -5px;
  vertical-align: middle;
}
.ant-layout-header .navbar-left .sidebar-trigger .anticon {
  font-size: 12px;
}

.ant-layout-header .navbar-right .dropdown-trigger {
  cursor: pointer;
}

.main-layout .ant-layout-sider {
  height: 100vh !important;
  overflow-y: scroll;
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
}

/* width */
.main-layout .ant-layout-sider::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.main-layout .ant-layout-sider::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 50px;
}
 
/* Handle */
.main-layout .ant-layout-sider::-webkit-scrollbar-thumb {
  background: #d3d3d3; 
  border-radius: 5px;
}


.main-layout .ant-layout-sider-trigger {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.main-layout .ant-layout-sider .ant-menu-item:hover {
  color: #007aff !important;
}

.main-layout .ant-layout-sider .ant-menu-sub.ant-menu-inline {
  margin-top: 0;
  background: transparent;
}

.main-layout .ant-layout-sider .ant-menu-sub.ant-menu-inline .ant-menu-item:last-child {
  margin-bottom: 0;
}

.main-layout .sidebar-header {
  display: flex;
  padding: 0.6rem 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.main-layout .sidebar-header .logo {
  width: 100px;
}

.main-layout .sidebar-header .sidebar-toggler .anticon {
  margin-top: -5px;
  vertical-align: middle;
}

.ant-layout-sider-children .ant-menu {
  margin-top: 1rem;
}

.main-layout .ant-layout-content {
  padding: 24px 48px;
  min-height: calc(100vh - 130px);
}

.main-layout .ant-layout-footer {
  padding: 24px 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.main-layout .ant-layout-footer h6 {
  margin: 0;
}

@media (max-width: 992px) {
  .main-layout .ant-layout-header {
    padding-inline: 24px;
  }
  .main-layout .ant-layout-sider {
    /* display: block !important; */
    transition: all 0.25 ease-in-out !important;
  }
  .main-layout .ant-layout-sider.closed {
    transform: translateX(-100%);
  }
  .main-layout .ant-layout-sider.open {
    transform: translateX(0);
  }
  .main-layout .ant-layout-sider {
    z-index: 1;
    height: 100%;
    position: fixed;
  }
}

@media (max-width: 768px) {
  .main-layout .ant-layout-header {
    padding-inline: 24px;
  }
  .main-layout .ant-layout-content {
    padding-inline: 24px;
  }
}
