.float-label {
  position: relative;
}

.label {
  z-index: 100;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: calc(50% - 10px);
  transition: 0.2s ease-in-out all;
}

.as-placeholder {
  color: #666b78;
  font-size: 14px !important;
}

.as-label {
  top: -8px;
  font-size: 10px !important;
  background: #fff;
  border-radius: 7px;
  color: #979797;
  padding: 0 4px;
  margin-left: -4px;
}
