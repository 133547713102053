.otpContainer{
    justify-content: center;
}

.otpBox{
    width: 40px;
    height: 40px;
    font-size: 36px;
    font-weight: lighter;
    text-align: center;
    border: 2px solid #d9a46e;
    border-radius: 6px;
}