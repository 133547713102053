@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Lato", sans-serif;
}

.img-fluid {
  height: "auto";
  max-width: 100%;
}

.ant-btn-primary {
  background-color: #007aff !important;
}

.ant-btn-primary:disabled {
  background-color: #979797 !important;
  color: #fff;
}

.ant-slider .ant-slider-track {
  height: 8px;
  background-color: #007aff;
}

.ant-slider-horizontal .ant-slider-dot {
  inset-block-start: 0;
}

.no-internet-toast .react-toast-notifications__toast__dismiss-button {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

/* .dark-theme .apexcharts-xaxis-label,
.dark-theme .apexcharts-yaxis-label {
  fill: #fff;
}

.dark-theme .apexcharts-legend-text,
.dark-theme .apexcharts-xaxistooltip-text {
  color: #fff !important;
}

.dark-theme .apexcharts-menu {
  background: #141414;
}

.dark-theme .apexcharts-menu .apexcharts-menu-item:hover {
  background: #000;
}

.dark-theme .apexcharts-tooltip,
.dark-theme .apexcharts-xaxistooltip-bottom,
.dark-theme .apexcharts-tooltip .apexcharts-tooltip-title {
  background: #000 !important;
}

.dark-theme .swal-modal,
.dark-theme .swal-modal .swal-icon:before,
.dark-theme .swal-modal .swal-icon:after,
.dark-theme .swal-modal .swal-icon--success__hide-corners {
  background-color: #141414;
}

.dark-theme .swal-modal .swal-title,
.dark-theme .swal-modal .swal-text {
  color: #fff;
} */
