.selfonboarding-welcome-web h1 {
  font-size: 30px;
  margin-bottom: 16px;
  color: rgba(35, 45, 76, 1);
  font-weight: bold;
}

.selfonboarding-welcome-web .details h1 {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: bold;
  color: #212121;
}

.image-carausel-modal .ant-modal-content {
  padding: 0 !important;
  /* width: 70%; */
  width: fit-content;
  margin: auto;
  overflow: hidden;
}

.selfonboarding-welcome-web h2 {
  font-size: 16px;
  font-weight: bold;
}

.selfonboarding-welcome-web p {
  font-size: 16px;
}

.selfonboarding-welcome-web .secondary {
  font-size: 16px;
  color: rgba(102, 107, 123, 1);
}

.selfonboarding-welcome-web .device-delivery p {
  color: #232d4c;
}
.selfonboarding-welcome-web .device-delivery .title {
  font-size: 28px;
  font-weight: bold;
}

.selfonboarding-welcome-web .device-card {
  width: 148px;
  height: 126px;
  border-radius: 14px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.selfonboarding-welcome-web .device-card img {
  width: 53px;
  height: 53px;
}

/* Stepper */
.ant-steps .ant-steps-item .ant-steps-item-content {
  margin-top: 0 !important;
}

.ant-steps .ant-steps-item-active .ant-steps-item-content .ant-steps-item-title {
  color: #007aff !important;
  font-weight: bold;
}

.map-container {
  width: 100%;
  height: 40vh;
  border-radius: 12px;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
