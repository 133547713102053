.ant-tabs-left .ant-tabs-nav {
  min-width: 200px;
}

.ant-tabs-left .ant-tabs-ink-bar {
  display: none;
}

.ant-slider .ant-slider-rail {
  height: 8px;
}

.ant-slider .ant-slider-handle {
  inset-block-start: 3px;
}

.info-box {
  width: 12px;
  height: 12px;
  border-radius: 3px;
}
