.welcome-card .illustration-wrapper {
  top: -0.75rem;
  right: -1.25rem;
  position: absolute;
}

.welcome-card .illustration-wrapper img {
  width: 200px;
}

@media (max-width: 576px) {
  .welcome-card .flex {
    align-items: center;
    flex-direction: column;
  }
  .welcome-card .text-wrapper {
    order: 2;
  }
  .welcome-card .illustration-wrapper {
    order: 1;
    top: unset;
    right: unset;
    position: relative;
  }
  .welcome-card .illustration-wrapper img {
    width: 300px;
  }
}
